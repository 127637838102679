<template>
    <div>
        <div class="page_header">
            <div class="btn-nav" @click="tapNav">
                <span class="icon-bar top"></span>
                <span class="icon-bar middle"></span>
                <span class="icon-bar bottom"></span>
            </div>
            <div class="page_title">{{ tabs[idx].label }}</div>
        </div>
        <div class="header" :style="{ 'background-image': `url(${tabs[idx].src})` }">
            <div class="mobile_tabBox">
                <div class="nav nav-content hideNav hidden">
                    <div class="nav_bar">
                        <!-- 这里的位置留给页面顶部导航 -->
                    </div>
                    <div class="nav_bar pd20" v-for="(item, i) in tabs" :key="i" :class="{ 'tab_default': idx === i }"
                        @click="tapClick(i)">
                        {{ item.label }}
                    </div>
                </div>
            </div>
            <div class="header_title">{{ tabs[idx].title }}</div>
            <div class="header_desc">{{ tabs[idx].desc }}</div>
        </div>
        <div v-if="show" class="fixed_box" :style="{ background: bgColor1 }">
            <div class="fixed">
                <img class="logo" :src="require(`@/assets/img/${logo}.png`)" alt="">
                <div class="tab">
                    <span v-for="(item, index) in tabs" :key="index">
                        <span v-if="item.showHeader" :style="{ color: deFontColor }" :class="['tab_item flex_c', {
                'tab_active': !isScroll && (idx === index),
                'tab_default': isScroll && (idx === index)
            }]" @click="tapClick(index)">
                            <template v-if="index <= 5">
                                <div class="line_bar" v-if="idx === index" :style="{ background: fontColor }"></div>
                                {{ item.label }}
                            </template>
                            <img v-if="index === 1 || index === 3" class="arrow_bottom"
                                :src="require(`@/assets/img/${arrow}.png`)" alt="">
                            <div v-if="index === 1" class="showAboutUs_box">
                                <div class="us_item" v-for="(listItem, j) in prodList" :key="j"
                                    @click.stop="tapJump(j)">
                                    {{ listItem.label }}</div>
                            </div>
                            <div v-if="index === 3" class="showAboutUs_box">
                                <div class="us_item" v-for="(list, i) in usList" :key="i" @click="tapScroll(i)">
                                    {{ list.label }}</div>
                            </div>
                        </span>
                    </span>
                </div>
                <div class="flex_c">
                    <div class="search flex_c" :style="{ background: bgColor2 }">
                        <img class="icon_search" :src="require(`@/assets/img/${search}.png`)" alt="">
                    </div>
                    <img class="icon_tel" :src="require(`@/assets/img/${tel}.png`)" alt="">
                    <div class="tel" :style="{ color: fontColor }">0571-56978456</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    name: 'Nav',
    props: {
        idx: {
            type: Number,
            default: 0
        },
        show: {	// 关于我们页面 锚点导航栏显示
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isScroll: false,	// 是否滚动
            prodList: [
                { label: '智慧养老', routerName: 'productService' },
                { label: '智慧社区', routerName: 'productService3' },
                { label: '智慧物联', routerName: 'productService2' },
                { label: '智慧应急', routerName: 'productService4' },
            ],
            usList: [
                { label: '公司简介', id: 'intro' },
                { label: '发展历程', id: 'devlop' },
                { label: '服务理念', id: 'idea' },
                { label: '企业文化', id: 'enterprise' },
                { label: '团队文化', id: 'team' },
            ],
            tabs: [
                {
                    label: '首页',
                    routerName: '/',
                    src: require('@/assets/img/index/banner.jpg'),
                    title: '志存高远，厚德载物',
                    desc: '专注智慧养老，运用大数据、物联网、人工智能等技术对传统养老服务业态进行升级改造',
                    showHeader: true,
                }, {
                    label: '产品服务',
                    routerName: 'productService',
                    src: require('@/assets/img/prod1/banner.png'),
                    title: '智慧养老',
                    desc: '全新的为老服务模式',
                    showHeader: true,
                }, {
                    label: '项目案例',
                    routerName: 'projectCase',
                    src: require('@/assets/img/case/banner.jpg'),
                    title: '“互联网+”智慧养老全新的为老服务模式',
                    desc: '高厚智能科技2017年前是一家智能软硬件科技公司，2017年转型为线下线上相结合的养老服务商，以智能硬件+线上产品服务，保证每一位老人的需求都被聆听！',
                    showHeader: true,
                }, {
                    label: '关于我们',
                    routerName: 'aboutUs',
                    src: require('@/assets/img/about-us.jpg'),
                    title: '用我们的爱心\n托起老人幸福的晚年',
                    desc: '我们一直在坚持',
                    list: [
                        { label: '养老设施' },
                        { label: '商家申请' },
                    ],
                    showHeader: true,
                }, {
                    label: '联系我们',
                    routerName: 'contactUs',
                    src: require('@/assets/img/contact-bg.jpg'),
                    title: '关爱今天的老人\n就是关爱明天的自己',
                    desc: '欢迎垂询',
                    showHeader: true,
                },  {
                    label: '应用下载',
                    routerName: 'download',
                    src: require('@/assets/img/prod4Img/bg.png'),
                    title: '应用下载',
                    desc: '自主研发具备高性能、强隐私保护，智力打造一站式服务',
                    showHeader: true,
                },{
                    label: '智慧养老',
                    routerName: 'productService',
                    src: require('@/assets/img/prod1/banner.png'),
                    title: '关爱今天的老人\n就是关爱明天的自己',
                    desc: '欢迎垂询'
                }, {
                    label: '智慧社区',
                    routerName: 'productService3',
                    src: require('@/assets/img/prod1/banner2.png'),
                    title: '智慧社区',
                    desc: '科技让生活更舒心'
                }, {
                    label: '智慧物联',
                    routerName: 'productService2',
                    src: require('@/assets/img/prod1/banner3.png'),
                    title: '智慧物联',
                    desc: '生活因智慧而改变'
                }, {
                    label: '智慧应急',
                    routerName: 'productService4',
                    src: require('@/assets/img/prod1/banner4.png'),
                    title: '智慧应急',
                    desc: '生活因智慧而改变'
                },
            ],
        }
    },
    computed: {
        logo() {
            return this.isScroll ? 'logo2' : 'logo1'
        },
        arrow() {
            return this.isScroll ? (this.idx === 3 ? 'arrow2' : 'arrow1') : 'arrow3'
        },
        search() {
            return this.isScroll ? 'search2' : 'search'
        },
        tel() {
            return this.isScroll ? 'tel2' : 'tel'
        },
        bgColor1() {
            return this.isScroll ? 'white' : 'rgba(0,0,0,.3)'
        },
        bgColor2() {
            return this.isScroll ? '#A5C5FF' : 'rgba(255, 255, 255, .4)'
        },
        fontColor() {
            return this.isScroll ? '#1969F9' : '#FFFFFF'
        },
        deFontColor() {
            return this.isScroll ? '#383838' : '#FFFFFF'
        },
    },
    created() { },
    mounted() {
        window.addEventListener('scroll', this.EventScroll)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.EventScroll)
    },
    methods: {
        EventScroll() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
            this.isScroll = scrollTop > 0
        },
        tapClick(i) {
            this.$emit('link', this.tabs[i].routerName)
        },
        tapJump(i) {
            this.$emit('link', this.prodList[i].routerName)
        },
        tapScroll(i) {
            this.$emit('link', this.tabs[3].routerName)
            this.$emit('menu', this.usList[i].id)
        },
        tapNav() { // 显示(隐藏) 导航栏
            $('.nav-content').toggleClass('showNav hideNav').removeClass('hidden');
            $('.btn-nav').toggleClass('animated');
        },
    }
}

</script>
<style lang='less' scoped>
@import '~@/assets/css/styles.css';
@media screen and (max-width: 767px) {

    // 移动端
    .page_header {
        padding: 15px;
        background-color: white;
        box-sizing: border-box;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0F5CE6;
        display: flex;
        align-items: center;

        .page_title {
            width: calc(100% + 56px - 15px);
            text-align: center;
        }

        .btn-nav {
            position: absolute;
            transform: scale(0.6);
            top: -2px;
            left: -2px;

            .icon-bar {
                background-color: #A3A3A3;
            }
        }
    }

    .header {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        color: #FFFFFF;
        font-weight: 500;
        font-family: PingFangSC-Medium, PingFang SC;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 140px 0;
        background-position: center;

        .mobile_tabBox {
            box-sizing: border-box;
            padding: 0 15px;

            .nav {
                width: 100%;
                height: 100%;
                overflow-y: scroll;
                background-color: white;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 999;

                .tab_default {
                    color: #1969F9 !important;
                }

                .pd20 {
                    padding: 0 20px;
                }

                .nav_bar {
                    color: #585858;
                    font-size: 15px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 90%;
                    height: 55px;
                    box-sizing: border-box;
                    line-height: 55px;
                    text-align: left;
                    margin: auto;
                    border-bottom: 1px solid #F2F2F2;
                }
            }
        }

        .header_title {
            font-size: 35px;
            margin: auto;
            padding: 0 15px;
            box-sizing: border-box;
            white-space: break-spaces;
        }

        .header_desc {
            line-height: 1.5;
            font-size: 15px;
            padding: 0 15px;
            letter-spacing: 1px;
            margin: 10px auto;
        }
    }

    .fixed_box {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    // iPad

}

@media screen and (min-width: 992px) {

    // PC端
    .flex_c {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page_header {
        display: none;
    }

    .header {
        width: 100%;
        min-width: 1200px;
        height: 660px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        color: #fff;
        text-align: center;
        font-weight: 500;
        text-shadow: 0 2px 4px rgba(0, 0, 0, .61);
        font-family: PingFangSC-Medium, PingFang SC;
        background-size: 100% 100%;

        .mobile_tabBox {
            display: none;
        }

        .header_title {
            font-size: 80px;
            width: 1200px;
            margin: auto;
            padding: 0 15px;
            box-sizing: border-box;
            padding-top: 214px;
            white-space: break-spaces;
        }

        .header_desc {
            width: 719px;
            line-height: 1.5;
            font-size: 22px;
            letter-spacing: 1px;
            margin: 10px auto;
        }
    }

    .fixed_box {
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 22;
        box-shadow: 0px 5px 19px 0px rgba(131, 144, 188, 0.15);

        .fixed {
            box-sizing: border-box;
            width: 1200px;
            margin: auto;
            height: 74px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            .logo {
                width: 263px;
                height: 36px;
            }

            .tab {
                display: flex;

                .tab_active {
                    color: #FFFFFF !important;
                }

                .tab_default {
                    color: #1969F9 !important;
                }

                .tab_item {
                    margin: 0 18px;
                    cursor: pointer;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    height: 74px;
                    font-weight: 400;
                    box-sizing: border-box;
                    position: relative;

                    .line_bar {
                        width: 23px;
                        height: 2px;
                        background: #1969F9;
                        border-radius: 1px;
                        position: absolute;
                        bottom: 2px;
                    }

                    .showAboutUs_box {
                        display: none;
                    }
                }

                .tab_item:hover .showAboutUs_box {
                    display: block;
                    position: absolute;
                    top: 74px;
                    text-align: center;
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);

                    .us_item {
                        width: 184px;
                        height: 48px;
                        line-height: 48px;
                        text-align: center;
                        background: white;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #373C43;

                        &:hover {
                            border-radius: 3px;
                            transform: scale(1.13);
                            color: #2176DB !important;
                            box-shadow: 0px 0px 7px 0px rgba(205, 205, 205, 0.5);
                        }
                    }
                }

                .arrow_bottom {
                    width: 12px;
                    height: 12px;
                    margin-left: 9px;
                }
            }

            .search {
                width: 35px;
                height: 35px;
                line-height: 35px;
                border-radius: 50%;

                .icon_search {
                    width: 15px;
                    height: 15px;
                }
            }

            .icon_tel {
                margin-left: 23px;
                width: 18px;
                height: 19px;
            }

            .tel {
                font-size: 18px;
                margin-left: 15px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
            }
        }
    }
}
</style>