<template>
	<div class="footer">
		<div class="safe_width">
			<div class="layout">
				<div class="half_le">
					<div class="tel">0571-56978456</div>
					<div class="working-time">周一至周日24小时服务</div>
					<!-- div class="qrCode-box">
						<img class="qrCode" :src="require('@/assets/img/qrCode.png')" alt="">
						<div class="concern">关注公众号</div>
					</div -->
					<!-- div class="methods">
						<img class="wechar-qq" :src="require('@/assets/img/wechar.png')" alt="">
						<img class="wechar-qq marginLeft13" :src="require('@/assets/img/qq.png')" alt="">
					</div -->
				</div>
				<div class="half_rig" style="justify-content: flex-end;">
					<div class="qrCode-box" style="margin-right: 20px;">
						<img class="qrCode" :src="require('@/assets/img/apk-qrcode.png')" style="width: 224px;height: 224px;" alt="">
						<div class="concern" align=center>扫码下载呼麦app<br><br></div>
					</div>
					<div class="qrCode-box">
						<img class="qrCode" :src="require('@/assets/img/qrCode.png')" style="width: 224px;height: 224px;" alt="">
						<div class="concern" align=center>关注公众号<br><br></div>
					</div>
				</div>
				<!-- div class="half_rig">
					<div>
						<div class="ul_title">产品</div>
						<div class="ul_subtitle">产品</div>
						<div class="ul_subtitle">产品</div>
						<div class="ul_subtitle">产品</div>
					</div>
					<div>
						<div class="ul_title">资源中心</div>
						<div class="ul_subtitle">资源中心</div>
						<div class="ul_subtitle">资源中心</div>
						<div class="ul_subtitle">资源中心</div>
					</div>
					<div>
						<div class="ul_title">开发者</div>
						<div class="ul_subtitle">开发者</div>
						<div class="ul_subtitle">开发者</div>
						<div class="ul_subtitle">开发者</div>
						<div class="ul_subtitle">开发者</div>
						<div class="ul_subtitle">开发者</div>
					</div>
					<div>
						<div class="ul_title">关于我们</div>
						<div class="ul_subtitle">关于我们</div>
						<div class="ul_subtitle">关于我们</div>
						<div class="ul_subtitle">关于我们</div>
						<div class="ul_subtitle">关于我们</div>
					</div>
				</div -->
			</div>
			<div class="addr">企业地址:杭州市杭州市拱墅区东文路与新北街交叉口星享书房<span class="phone"> 企业电话:0571-56978456</span></div>
			<div class="keepRecord"><a target="_blank"
					href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010302001999" style="color: #93A2B7;">
					<img :src="require('@/assets/img/icon-beian.png')"> 浙公网安备 33010302001999号</a>&nbsp;&nbsp;&nbsp;<a
					class="foot-link" href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank"
					style="color: #93A2B7;"><span>浙ICP备14008212号-6</span></a>&nbsp;|&nbsp;<span>版权所有 &copy; 2011 - 现在
					&nbsp;&nbsp;杭州高厚智能科技有限公司. 版权所有.</span></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
	data() {
		return {

		}
	},
	created() { },
	methods: {}
}

</script>
<style lang='less' scoped>
@media screen and (max-width: 767px) {

	// 移动端
	.footer {
		padding: 25px 0;
		background: #1F2D3D;
		text-align: center;
		font-family: PingFangSC-Medium, PingFang SC;
		color: #FFFFFF;

		.tel {
			font-size: 24px;
			font-weight: 500;
		}

		.working-time {
			font-weight: 400;
			margin-top: 8px;
			font-size: 13px;
		}

		.qrCode-box {
			width: 170px;
			height: 170px;
			margin: 25px auto;
			padding: 23px 30px 12px;
			box-sizing: border-box;
			border: 2px solid #E3E3E3;

			.qrCode {
				width: 100%;
			}

			.concern {
				font-size: 13px;
				font-weight: 400;
				color: #93A2B7;
				margin-top: 6px;
			}
		}

		.methods {
			padding-bottom: 18px;
			border-bottom: 1px solid #F0F0F0;

			.marginLeft13 {
				margin-left: 13px;
			}

			.wechar-qq {
				width: 56px;
			}
		}

		.half_rig {
			flex: 1;
			display: flex;
			// justify-content: space-around;
		}

		.addr {
			padding: 0 10px;
			margin-top: 14px;
			font-size: 12px;
			font-weight: 500;
			color: #93A2B7;
		}

		.keepRecord {
			padding: 0 15px;
			margin-top: 6px;
			font-size: 12px;
			transform: scale(.9);
			font-weight: 400;
			color: #93A2B7;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	// iPad

}

@media screen and (min-width: 992px) {

	// PC端
	.footer {
		padding: 78px 0 60px;
		background: #1F2D3D;
		font-family: PingFangSC-Medium, PingFang SC;
		color: #FFFFFF;

		.safe_width {
			// text-align: center;
			width: 1200px;
			margin: auto;

			.layout {
				display: flex;

				.half_le {
					.tel {
						font-size: 48px;
						font-weight: 500;
					}

					.working-time {
						font-weight: 400;
						margin-top: 8px;
						font-size: 24px;
					}

					.qrCode-box {
						width: 170px;
						height: 170px;
						margin: 25px 0;
						padding: 23px 30px 12px;
						box-sizing: border-box;
						border: 2px solid #E3E3E3;

						.qrCode {
							width: 100%;
						}

						.concern {
							font-size: 17px;
							font-weight: 400;
							color: #93A2B7;
						}
					}

					.methods {
						width: 170px;
						text-align: center;
						padding-bottom: 18px;

						.marginLeft13 {
							margin-left: 13px;
						}

						.wechar-qq {
							width: 56px;
						}
					}
				}

				.half_rig {
					flex: 1;
					margin-left: 115px;
					display: flex;
					justify-content: space-around;

					.ul_title {
						font-size: 20px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #FFFFFF;
					}

					.ul_subtitle {
						margin-top: 26px;
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #93A2B7;
					}
				}
			}

			.addr {
				text-align: center;
				border-top: 1px solid #F0F0F0;
				padding: 0 15px;
				padding-top: 14px;
				font-size: 16px;
				font-weight: 500;
				color: #93A2B7;

				.phone {
					margin-left: 20px;
				}
			}

			.keepRecord {
				text-align: center;
				padding: 0 15px;
				margin-top: 6px;
				font-size: 14px;
				transform: scale(.9);
				font-weight: 400;
				color: #93A2B7;
			}
		}
	}
}</style>